// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_response_recordEventForLoggedInUser(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["EventRecorded"]);
}

var variablesConverter = {"recordEventForLoggedInUserInput":{"event":{"r":"recordEventV5EventInput"}},"recordEventV5EventInput":{"occurredAt":{"b":""},"data":{"b":""}},"__root":{"input":{"r":"recordEventForLoggedInUserInput"}}};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"recordEventForLoggedInUser":{"u":"response_recordEventForLoggedInUser"}}};

var wrapResponseConverterMap = {
  response_recordEventForLoggedInUser: RescriptRelay_Internal.wrapUnion
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"recordEventForLoggedInUser":{"u":"response_recordEventForLoggedInUser"}}};

var responseConverterMap = {
  response_recordEventForLoggedInUser: unwrap_response_recordEventForLoggedInUser
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventLogs_RecordEventForLoggedInUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "recordEventForLoggedInUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v3/*: any*/),
            "type": "EventRecorded",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventLogs_RecordEventForLoggedInUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "recordEventForLoggedInUser",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2fb84943d41b75fb1b384edd0be5beba",
    "id": null,
    "metadata": {},
    "name": "EventLogs_RecordEventForLoggedInUserMutation",
    "operationKind": "mutation",
    "text": "mutation EventLogs_RecordEventForLoggedInUserMutation(\n  $input: RecordEventForLoggedInUserInput!\n) {\n  recordEventForLoggedInUser(input: $input) {\n    __typename\n    ... on EventRecorded {\n      __typename\n    }\n  }\n}\n"
  }
};
})());

var wrap_response_recordEventForLoggedInUser = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_response_recordEventForLoggedInUser = unwrap_response_recordEventForLoggedInUser;
exports.wrap_response_recordEventForLoggedInUser = wrap_response_recordEventForLoggedInUser;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
