// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function PromiseBox(p) {
    this.nested = p;
};

function unbox(value) {
    if (value instanceof PromiseBox)
        return value.nested;
    else
        return value;
}

function box(value) {
    if (value != null && typeof value.then === 'function')
        return new PromiseBox(value);
    else
        return value;
}

function make(executor) {
    return new Promise(function (resolve, reject) {
        var boxingResolve = function(value) {
            resolve(box(value));
        };
        executor(boxingResolve, reject);
    });
};

function resolved(value) {
    return Promise.resolve(box(value));
};

function then(promise, callback) {
    return promise.then(function (value) {
        try {
            return callback(unbox(value));
        }
        catch (exception) {
            onUnhandledException.contents(exception);
            return new Promise(function() {});
        }
    });
};

function catch_(promise, callback) {
    var safeCallback = function (error) {
        try {
            return callback(error);
        }
        catch (exception) {
            onUnhandledException.contents(exception);
            return new Promise(function() {});
        }
    };

    return promise.catch(safeCallback);
};
;

function pending() {
  var resolve = {
    contents: (function (prim) {
        
      })
  };
  var reject = {
    contents: (function (prim) {
        
      })
  };
  var p = make(function (resolve$p, reject$p) {
        resolve.contents = resolve$p;
        reject.contents = reject$p;
      });
  return [
          p,
          resolve.contents,
          reject.contents
        ];
}

function map(promise, callback) {
  return then(promise, (function (v) {
                return resolved(callback(v));
              }));
}

function get(promise, callback) {
  map(promise, callback);
}

function tap(promise, callback) {
  return map(promise, (function (v) {
                callback(v);
                return v;
              }));
}

function all2(p1, p2) {
  return Promise.all([
              p1,
              p2
            ]);
}

function toResult(promise) {
  return catch_(map(promise, (function (v) {
                    return {
                            TAG: "Ok",
                            _0: v
                          };
                  })), (function (e) {
                return resolved({
                            TAG: "Error",
                            _0: e
                          });
              }));
}

function fromResult(promise) {
  return then(promise, (function (x) {
                if (x.TAG === "Ok") {
                  return resolved(x._0);
                } else {
                  return Promise.reject(x._0);
                }
              }));
}

function pending$1() {
  var match = pending();
  return [
          match[0],
          match[1]
        ];
}

function resolved$1(prim) {
  return resolved(prim);
}

function mapOk(promise, callback) {
  return map(promise, (function (result) {
                if (result.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: callback(result._0)
                        };
                } else {
                  return result;
                }
              }));
}

function getOk(promise, callback) {
  get(promise, (function (result) {
          if (result.TAG === "Ok") {
            return callback(result._0);
          }
          
        }));
}

function getError(promise, callback) {
  get(promise, (function (result) {
          if (result.TAG === "Ok") {
            return ;
          } else {
            return callback(result._0);
          }
        }));
}

function tapOk(promise, callback) {
  getOk(promise, callback);
  return promise;
}

function tapError(promise, callback) {
  getError(promise, callback);
  return promise;
}

function Js_fromBsPromise(prim) {
  return prim;
}

function Js_toBsPromise(prim) {
  return prim;
}

var Js = {
  toResult: toResult,
  fromResult: fromResult,
  fromBsPromise: Js_fromBsPromise,
  toBsPromise: Js_toBsPromise
};

exports.pending = pending$1;
exports.resolved = resolved$1;
exports.get = get;
exports.tap = tap;
exports.map = map;
exports.getOk = getOk;
exports.tapOk = tapOk;
exports.mapOk = mapOk;
exports.tapError = tapError;
exports.all2 = all2;
exports.Js = Js;
/*  Not a pure module */
