// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Sentry = require("./Sentry.bs.js");
var Scalars = require("../scalars/Scalars.bs.js");
var TanDate = require("./common/types/TanDate.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var ReasonPromise = require("./ReasonPromise.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var EventLogs_RecordEventV5Mutation_graphql = require("../__generated__/EventLogs_RecordEventV5Mutation_graphql.bs.js");
var EventLogs_RecordEventForLoggedInUserMutation_graphql = require("../__generated__/EventLogs_RecordEventForLoggedInUserMutation_graphql.bs.js");

function toString(x) {
  switch (x) {
    case "Logout" :
        return "logout";
    case "Login" :
        return "login";
    case "DuplicateAddressWarning" :
        return "duplicate_address_warning";
    case "SubscriptionsReconnected" :
        return "subscriptions_reconnected";
    case "ClientHomePageAreaSearch" :
        return "client_home_page_area_search";
    case "ClientHomePagePropertySearch" :
        return "client_home_property_search";
    case "EmailActivityViewProfile" :
        return "email_activity_view_profile";
    
  }
}

var EventKey = {
  toString: toString
};

var convertVariables = EventLogs_RecordEventV5Mutation_graphql.Internal.convertVariables;

var convertResponse = EventLogs_RecordEventV5Mutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = EventLogs_RecordEventV5Mutation_graphql.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, EventLogs_RecordEventV5Mutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, EventLogs_RecordEventV5Mutation_graphql.node, convertResponse, convertWrapRawResponse);

var RecordEventV5Mutation_actorTypeV5_decode = EventLogs_RecordEventV5Mutation_graphql.Utils.actorTypeV5_decode;

var RecordEventV5Mutation_actorTypeV5_fromString = EventLogs_RecordEventV5Mutation_graphql.Utils.actorTypeV5_fromString;

var RecordEventV5Mutation = {
  actorTypeV5_decode: RecordEventV5Mutation_actorTypeV5_decode,
  actorTypeV5_fromString: RecordEventV5Mutation_actorTypeV5_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

var convertVariables$1 = EventLogs_RecordEventForLoggedInUserMutation_graphql.Internal.convertVariables;

var convertResponse$1 = EventLogs_RecordEventForLoggedInUserMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = EventLogs_RecordEventForLoggedInUserMutation_graphql.Internal.convertWrapRawResponse;

var commitMutation$1 = RescriptRelay_Mutation.commitMutation(convertVariables$1, EventLogs_RecordEventForLoggedInUserMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, EventLogs_RecordEventForLoggedInUserMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var RecordEventForLoggedInUserMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation$1,
  use: use$1
};

function useRecordEvent() {
  var match = use();
  var mutate = match[0];
  return function (actor, data, key, param) {
    var match = ReasonPromise.pending();
    var resolve = match[1];
    mutate({
          input: {
            actor: {
              actorId: actor[1],
              actorType: actor[0]
            },
            event: {
              data: Core__Option.map(data, (function (d) {
                      return d;
                    })),
              key: toString(key),
              occurredAt: Scalars.DateTime.fromJsDate(TanDate.now())
            }
          }
        }, undefined, undefined, undefined, (function (param, param$1) {
            resolve();
          }), (function (e) {
            Sentry.captureException(new Error(e.message), "EventLogs.res", 71, {
                  exn: e
                }, undefined);
            resolve();
          }), undefined);
    return match[0];
  };
}

function useRecordEventByCurrentUser() {
  var match = use$1();
  var mutate = match[0];
  return function (data, key, param) {
    var match = ReasonPromise.pending();
    var resolve = match[1];
    mutate({
          input: {
            event: {
              data: Core__Option.map(data, (function (d) {
                      return d;
                    })),
              key: toString(key),
              occurredAt: Scalars.DateTime.fromJsDate(TanDate.now())
            }
          }
        }, undefined, undefined, undefined, (function (param, param$1) {
            resolve();
          }), (function (e) {
            Sentry.captureException(new Error(e.message), "EventLogs.res", 108, {
                  exn: e
                }, undefined);
            resolve();
          }), undefined);
    return match[0];
  };
}

exports.EventKey = EventKey;
exports.RecordEventV5Mutation = RecordEventV5Mutation;
exports.RecordEventForLoggedInUserMutation = RecordEventForLoggedInUserMutation;
exports.useRecordEvent = useRecordEvent;
exports.useRecordEventByCurrentUser = useRecordEventByCurrentUser;
/* commitMutation Not a pure module */
